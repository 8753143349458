console.log('components');

import { swipers } from '../js/components/swipers';
swipers();

import { productCard } from '../js/components/productCard';
productCard();

import { autoRadio } from '../js/components/autoRadio';
autoRadio();

import { accordion } from '../js/components/accordion';
accordion();

import { mobileMenu } from '../js/components/mobileMenu';
mobileMenu();

import { selects } from '../js/components/selects';
selects();

import { filters } from '../js/components/filters';
filters();

import { modals } from '../js/components/modals';
modals();

import { jqRange } from '../js/components/jqRange';
jqRange();

import { productBtn } from '../js/components/productBtn';
productBtn();