export const selects = () => {

    const selects = document.querySelectorAll(".js-select");    

    for (let select of selects ){
        let options = select.querySelectorAll(".select__option");
        let labels = select.querySelectorAll(".select__label")
        let input = select.querySelector(".select__input");
        let value = select.querySelector(".select__value");
        let list = select.querySelector(".select__list");
        let clear = select.querySelector(".select__clear");

        value.addEventListener("click",function(e){

            if ( e.target.parentElement.classList.contains("select_open") ) {
                document.querySelector(".select_open")?.classList.remove("select_open");
            } else {
                document.querySelector(".select_open")?.classList.remove("select_open");
                select.classList.add("select_open");
            }
            
        });
        
        if ( options.length ) {
            for (let option of options) {
                option.addEventListener("click",function(e){
                    select.querySelector('.select__option_selected')?.classList.remove("select__option_selected");
                    option.classList.add("select__option_selected");
    
                    input.value = option.dataset.value;
                    value.innerText = option.dataset.value;
                    //input.setAttribute("value", option.dataset.value);
                    input.setAttribute("data-full", "");
                    value.setAttribute("data-full", "");
                    select.classList.toggle("select_open");
    
                });
            }
        }

        if ( labels.length ) {
            for (let label of labels ) {
                label.addEventListener('click',function(e){
                    let count = 0;
                    for (let i of labels ) {
                        if ( i.querySelector("input").checked ){
                            count = count + 1;
                        }
                    }
                    if ( count > 0 ) {
                        input.value = count;
                        value.innerText = value.dataset.placeholder + ` (${count})`;
                        input.setAttribute("data-full", "");
                        value.setAttribute("data-full", "");
                    } else {
                        input.value = count;
                        value.innerText = value.dataset.placeholder;
                        input.removeAttribute("data-full", "");
                        value.removeAttribute("data-full", "");
                    }
                });
            }
        }

        if ( clear ) {
            clear.addEventListener('click',function(){
                let checks = clear.parentElement.querySelectorAll("input");
                for (let check of checks ) {
                    check.checked = false;
                }
                input.value = 0;
                value.innerText = value.dataset.placeholder;
                input.removeAttribute("data-full", "");
                value.removeAttribute("data-full", "");
            });
        }
        
    }


    document.addEventListener("click",function(e){

        //console.log('', e.target.classList);

        //!e.target.classList.contains("select__value")
        //!e.target.classList.contains("select__label")
        if ( !e.target.closest(".select__value") && !e.target.closest(".select__label") && !e.target.closest(".select__clear") ) {
            document.querySelector(".select_open")?.classList.remove("select_open");
        }

    });



    


}